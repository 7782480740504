import React, { useState } from "react";
// import { Row, Col } from "react-grid-system";
import PropTypes from "prop-types";
// import useDeviceSize from "../../../hooks/use-device-size";

export default function StickyCallout ({
  className,
  imgBgClassName,
  imgRef,
  imgRefMobile,
  imgAlt,
  ctaTitle,
  // stickyCalloutClass,
  // active,
  dwnlIcon,
  dwnlIconAlt,
  children,
  ismobile,
  ...props
  }){

  // const deviceSizes = useDeviceSize();

  const [display, setDisplay] = useState("notdisplayed");
  const [displayParent, setDisplayParent] = useState("off");
  const showButton = e => {
    e.preventDefault();
    setDisplay("displayed");
    setDisplayParent("on");
  };

  const hideButton = e => {
    e.preventDefault();
    setDisplay("notdisplayed");
    setDisplayParent("off");
  };

  return (
    <div {...props} className={`sticky-callout ${ismobile ? "neema": className}`}>
   {/*  <div {...props} className={`sticky-callout ${className}`}> */}
      <div
        className={`cout-box ${displayParent}`}
        onMouseEnter={e => showButton(e)}
        onMouseLeave={e => hideButton(e)}
      >
        <img src={dwnlIcon} alt={dwnlIconAlt} className="sticky-cout-icon" />
        <div className={display}>{children}</div>
      </div>
    </div>
  )
};

StickyCallout.propTypes = {
  className: PropTypes.string,
};
