import * as React from "react";
import { StickyCallout } from "../../callouts"
import ExternalLink from "../../external-link";
import { useSiteMetadata } from "../../../hooks/use-site-metadata";
import iconMedGuide from "../../../images/icons/icon-dwnl-med.png";

export default function MedicationGuide ({
  className,
  }){
  const { externalLinkInfo } = useSiteMetadata();
  return (
    <ExternalLink
    externalURL="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=b7d23ac2-e776-9f62-3290-c64c2d6eb353&type=display#section-17"
    messageTitle={externalLinkInfo.TITLE}
    messageBody={externalLinkInfo.BODY}
    classNameLink={externalLinkInfo.LINK_CLASS_NAME}
    classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}>
      <StickyCallout
      className={`cout-med-guide ${className}`}
      dwnlIcon={iconMedGuide}
      dwnlIconAlt="icon for medication guide download"
      >
        <div className="sticky-callout-content">
          <p className="title">Hysingla ER Medication Guide</p>
          <p>Download important information about this medication.</p>
        </div>
      </StickyCallout>
    </ExternalLink>
  )
};